import { FC } from 'react'

import { PlusIcon, SquaresPlusIcon } from '@heroicons/react/24/outline'

import { routes, navigate } from '@redwoodjs/router'

import Button from '../Library/Button/Button'

const CustomerIntegrationsHeader: FC<{ hideButton?: boolean }> = ({
  hideButton = false,
}) => {
  return (
    <div className="flex items-center justify-between pt-10 ">
      <p className="text-2xl flex gap-4 items-center">
        <span className="p-2 bg-indigo-100 rounded-full">
          <SquaresPlusIcon className="w-8 h-8 text-indigo-600" />
        </span>
        Integrations
      </p>
      {!hideButton && (
        <Button
          startIcon={<PlusIcon className="h-4 w-4" />}
          fullWidth={false}
          onClick={() => navigate(routes.customerIntegrationsNew())}
        >
          New Integration
        </Button>
      )}
    </div>
  )
}

export default CustomerIntegrationsHeader
